<template>
  <CFooter>
    <div class="ms-auto">
     
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
