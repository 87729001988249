
export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
    /*badge: {
      color: 'primary',
      text: 'NEW',
    },*/
  },
  {
    component: 'CNavTitle',
    name: 'candidates',
  },
  {
    component: 'CNavGroup',
    name:  'Students',
    to: '/students',
    icon: 'cilAddressBook',
    showIf: "store.user.permissions.includes('Colors')",
    items: [
      {
        component: 'CNavItem',
        name: 'Students',
        to: '/students',
      },
     /* {
        component: 'CNavItem',
        name: 'Add Students',
        to: '/students/add',
      },*/
    ],
  },
  {
    component: 'CNavTitle',
    name: 'Review',
  },
  {
    component: 'CNavGroup',
    name:  'Groups',
    to: '/groups',
    icon: 'cilCalculator',
    items: [
      {
        component: 'CNavItem',
        name: 'Groups',
        to: '/groups',
      },
      {
        component: 'CNavItem',
        name: 'Group Reviewers Report',
        to: '/groups/group-reviewer-report',
      },
      {
        component: 'CNavItem',
        name: 'Reader Scoring Students',
        to: '/groups/group-scoring-reviews-report',
      },
    ],
  },
 /* {
    component: 'CNavGroup',
    name:  'Application Readers',
    to: '/reader-students-report',
    icon: 'cil-file',
    items: [
      {
        component: 'CNavItem',
        name: 'Report',
        to: '/reader-students-report',
      },
    ],
  },*/



  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
]
