
export default [
    {
      component: 'CNavItem',
      name: 'Enrollment Files',
      to: '/enrollmentfiles',
      icon: 'cil-file',
      /*badge: {
        color: 'primary',
        text: 'NEW',
      },*/
    }

   /* {
      component: 'CNavGroup',
      name:  'Application Readers',
      to: '/reader-students-report',
      icon: 'cil-file',
      items: [
        {
          component: 'CNavItem',
          name: 'Report',
          to: '/reader-students-report',
        },
      ],
    },*/
  
  
  
    // {
    //   component: 'CNavItem',
    //   name: 'Download CoreUI',
    //   href: 'http://coreui.io/vue/',
    //   icon: { name: 'cil-cloud-download', class: 'text-white' },
    //   _class: 'bg-success text-white',
    //   target: '_blank'
    // },
    // {
    //   component: 'CNavItem',
    //   name: 'Try CoreUI PRO',
    //   href: 'http://coreui.io/pro/vue/',
    //   icon: { name: 'cil-layers', class: 'text-white' },
    //   _class: 'bg-danger text-white',
    //   target: '_blank'
    // }
  ]
  