export default {
  "Menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
  "Post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
  "Posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posts"])},
  "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "User Roles & Permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Roles & Permissions"])},
  "Role Permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role Permissions"])},
  "Role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
  "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers"])},
  "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
  "Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
  "Products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
  "Categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
  "All Customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Customers"])},
  "Leads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leads"])},
  "Clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
  "Lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead"])},
  "Client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
  "My Customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Customers"])},
  "My Leads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Leads"])},
  "My Clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Clients"])},
  "Notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
  "All Notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Notes"])},
  "Scheduled Notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled Notes"])},
  "My Notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Notes"])},
  "My Scheduled Notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Scheduled Notes"])},
  "Activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
  "All Activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Activities"])},
  "Open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
  "Alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert"])},
  "Priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priority"])},
  "Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
  "All Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Services"])},
  "Renewal Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renewal Services"])},
  "Permanent Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permanent Services"])},
  "Waiting Renewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting Renewal"])},
  "Waiting Validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting Validation"])},
  "My Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Services"])},
  "My Renewal Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Renewal Services"])},
  "My Permanent Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Permanent Services"])},
  "My Waiting Renewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Waiting Renewal"])},
  "My Waiting Validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Waiting Validation"])},
  "All Tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Tasks"])},
  "Open Tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Tasks"])},
  "Scheduled Tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheduled Tasks"])},
  "Closed Tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed Tasks"])},
  "Tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks"])},
  "Task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task"])},
  "My Tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Tasks"])},
  "My Open Tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Open Tasks"])},
  "My Scheduled Tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Scheduled Tasks"])},
  "My Closed Tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Closed Tasks"])},
  "Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])},
  "Performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance"])},
  "User Performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Performance"])},
  "My Performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Performance"])},
  "Setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setting"])},
  "Company Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Info"])},
  "Taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxes"])},
  "Users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
  "User Permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Permissions"])},
  "Customer Types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Types"])},
  "Reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reasons"])},
  "Results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results"])},
  "Interests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interests"])},
  "Types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types"])},
  "Reached By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reached By"])},
  "Currencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currencies"])},
  "Countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Countries"])},
  "States": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["States"])},
  "Cities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cities"])},
  "Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fields"])},
  "Tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax"])},
  "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
  "User Permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Permission"])},
  "Permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permission"])},
  "Permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
  "Customer Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Type"])},
  "Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
  "Result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result"])},
  "Interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interest"])},
  "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "Currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
  "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
  "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "Field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field"])},
  "Business Field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Field"])},
  "Post Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post Code"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "Value/Class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value/Class"])},
  "Website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
  "Logo image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo image"])},
  "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOTE"])},
  "Internal Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal Note"])},
  "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "View": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
  "Online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online"])},
  "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "Pervious": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pervious"])},
  "Account Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Manager"])},
  "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
  "Created at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created at"])},
  "Updated at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated at"])},
  "Action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
  "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "Validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validated"])},
  "Start Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
  "End Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
  "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "Assigned To": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned To"])},
  "Comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "Service History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service History"])},
  "Service Informaton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Informaton"])},
  "Add by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add by"])},
  "Customer Informaton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Informaton"])},
  "Last 7 days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 7 days"])},
  "Last 30 days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 30 days"])},
  "Last 3 Months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 3 Months"])},
  "Last 6 Months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 6 Months"])},
  "Last 12 Months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 12 Months"])},
  "Life Time Performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Life Time Performance"])},
  "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "Logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
  "Logo_white": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White Logo"])},
  "Logo_black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black Logo"])},
  "Company Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Information"])},
  "Customer Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Information"])},
  "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "Percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage"])},
  "Currency Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Name"])},
  "Unit Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Name"])},
  "Subunit Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subunit Name"])},
  "Currency Rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Rate"])},
  "Rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])},
  "Unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])},
  "Subunit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subunit"])},
  "Country Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Name"])},
  "Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
  "Calling Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calling Code"])},
  "Progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress"])},
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "Mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
  "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "Customer Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Profile"])},
  "Create Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Contact"])},
  "Edit Customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Customer"])},
  "Image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
  "Position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
  "Edit Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Contact"])},
  "Add Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Service"])},
  "Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "Service Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Details"])},
  "Validate & Invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate & Invoice"])},
  "Renewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renewal"])},
  "Print Invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print Invoice"])},
  "Edit Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Service"])},
  "Service will be invoiced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service will be invoiced and cannot be edited."])},
  "Validate Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate Service"])},
  "Validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate"])},
  "Add Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Note"])},
  "Mark As Seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark As Seen"])},
  "Seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seen"])},
  "Add Activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Activity"])},
  "Edit Activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Activity"])},
  "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "Interested in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interested in"])},
  "All Open Activites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Open Activites"])},
  "All Alert Activites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Alert Activites"])},
  "All Priority Activites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Priority Activites"])},
  "Show Activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Activity"])},
  "Done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
  "Mark As Done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark As Done"])},
  "Companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies"])},
  "Events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
  "Event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
  "Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "Contact Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Person"])},
  "Owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
  "Tax Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Number"])},
  "Commercial Registry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial Registry"])},
  "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
  "contactus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
  "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intro"])},
  "Edit About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit About"])},
  "Edit Intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Intro"])},
  "sliders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sliders"])},
  "Conferance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferance"])},
  "Attendants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendants"])},
  "Rejected Attendants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected Attendants"])},
  "Adult Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adult Number"])},
  "Childern Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Childern Number"])},
  "Childern Age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Childern Age"])},
  "Arrival Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrival Date"])},
  "Departure Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departure Date"])},
  "Flight Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flight Details"])},
  "Package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package"])},
  "Double Room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double Room"])},
  "Single Room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single Room"])},
  "Tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets"])},
  "Edit Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EDIT CONTACT US"])},
  "Languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
  "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "Providers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Providers"])},
  "Provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider"])},
  "our courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["our courses"])},
  "Courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courses"])},
  "Course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course"])},
  "Short Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short Description"])},
  "Discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
  "Skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skills"])},
  "Duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
  "Item_langs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item languages"])},
  "Item_lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item language"])},
  "Video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
  "Videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videos"])},
  "Sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions"])},
  "Session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session"])},
  "Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
  "Pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pages"])},
  "Slider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slider"])},
  "Sliders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sliders"])},
  "Navbar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navbar"])},
  "Follow Me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow Me"])},
  "Follow us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow us"])},
  "Recent Posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent Posts"])},
  "Get Interesting News": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Interesting News"])},
  "Older Posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Older Posts"])},
  "Newer Posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newer Posts"])},
  "Personal Blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Blog"])},
  "Read More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read More"])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subscribe"])},
  "Your email address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email address"])},
  "Continue Reading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue Reading"])},
  "YOU MIGHT ALSO LIKE...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YOU MIGHT ALSO LIKE..."])},
  "Subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
  "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
  "Thank you I will contact you as soon as possible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you I will contact you as soon as possible"])},
  "Shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop"])},
  "Catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalog"])},
  "More Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Services"])},
  "Customer Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Service"])},
  "Messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "Partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
  "bundles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundles"])},
  "bundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundle"])},
  "High Quality Courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Learning Now"])},
  "Save More With Our Bundles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save More With Our Bundles"])},
  "Start Learning Now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Learning Now"])},
  "Beginner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginner"])},
  "Intermediate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intermediate"])},
  "Advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced"])},
  "About the Course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the Course"])},
  "Course by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course by"])},
  "Read full biography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read full biography"])},
  "Views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Views"])},
  "Sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])},
  "Overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
  "Buy now and save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy now and save"])},
  "Buy now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy now"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Confirmation"])},
  "Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
  "My Courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Courses"])},
  "My Wishlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Wishlist"])},
  "Wishlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wishlist"])},
  "Personal Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Information"])},
  "Account Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Information"])},
  "Current Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Password"])},
  "New Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
  "Change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
  "Check out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check out"])},
  "Triple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triple"])},
  "Cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart"])},
  "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
  "Orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orders"])},
  "My Orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Orders"])},
  "Reference Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference Number"])},
  "Payment Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Status"])},
  "Payment Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Date"])},
  "Order Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Number"])},
  "Paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
  "Waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting"])},
  "Available Languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Languages"])},
  "Signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signup"])},
  "Remember Me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember Me"])},
  "View All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View All"])},
  "Share on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share on"])},
  "Now Playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now Playing"])},
  "Terms of Use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])},
  "Privacy Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["question"])},
  "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["questions"])},
  "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz"])},
  "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzes"])},
  "Answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer"])},
  "Answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answers"])},
  "Score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
  "Scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores"])},
  "Exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam"])},
  "Exams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exams"])},
  "Start The Exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start The Exam"])},
  "Minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minutes"])},
  "You need to buy the course for playing this": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to buy the course for playing this"])},
  "Reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
  "Leave a Review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave a Review"])},
  "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["review"])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "Quiz Questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz Questions"])},
  "Passing Degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passing Degree"])},
  "Group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
  "Groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groups"])},
  "Create Group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Group"])},
  "Members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members"])},
  "Member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member"])},
  "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["report"])},
  "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
  "Deactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivated"])},
  "Waiting Review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting Review"])},
  "Add Course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Course"])},
  "Add Member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Member"])},
  "Search results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search results"])},
  "Logicel For Business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logicel For Business"])},
  "Thank you we will contact you as soon as possible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you we will contact you as soon as possible"])},
  "Expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired"])},
  "Cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelled"])},
  "Failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
  "Created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created_at"])},
  "Payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
  "Payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments"])},
  "Ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready"])},
  "Cycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cycle"])},
  "Cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cycles"])},
  "Your Score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Score"])},
  "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "Pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass"])},
  "Fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail"])},
  "SUMMARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUMMARY"])},
  "TOTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL"])},
  "EGP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EGP"])},
  "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rate"])},
  "Forget Your Password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forget Your Password?"])},
  "Attempt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attempt"])},
  "Cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cover"])},
  "exams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exams"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "generate certifcate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate Certifcate"])},
  "cantTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can not take the exam right now, you have to wait: "])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["days"])},
  "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slug"])},
  "Media Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media Library"])},
  "promos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotions"])},
  "promo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
  "Date Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Start"])},
  "Date End": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date End"])},
  "No data or Promo is expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data or Promo is expired"])},
  "You have used a promo on this order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have used a promotion or it does not apply on this order"])},
  "applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["applied"])},
  "No items found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No items found"])},
  "Data has been updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data has been updated"])},
  "Please enter correct current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter correct current password"])},
  "statusDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Description"])},
  "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
  "Create New Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create New Account"])},
  "passhint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 8 letters or numbers"])},
  "Sorting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorting"])},
  "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job"])},
  "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student"])},
  "graduated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graduated"])},
  "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee"])},
  "employer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employer"])},
  "verify_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm registration"])},
  "Hi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi"])},
  "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks"])},
  "Your mail is verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your mail is verified"])},
  "Your mail is verified, thank you!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your mail is verified, thank you!"])},
  "No data found!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data found!"])},
  "Your mail is verified, you need to login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your mail is verified, you need to login"])},
  "check your mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your mail"])},
  "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resend"])},
  "Exercise Folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exercise Folder"])},
  "certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificates"])},
  "certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate"])},
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
  "uploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop image or click to upload"])},
  "commentUnderReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your comment is under review!"])},
  "facebookfollow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow us on Facebook"])},
  "twitterfollow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow us on Twitter"])},
  "linkedinfollow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow us on Linkedin"])},
  "instagramfollow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow us on Instagram"])},
  "youtubefollow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow us on Youtube"])},
  "Add to cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to cart"])},
  "Remove from cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from cart"])},
  "Add to wishlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to wishlist"])},
  "Remove from wishlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove from wishlist"])},
  "Play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play"])},
  "Facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
  "Twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter"])},
  "Linkedin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linkedin"])},
  "LogoutSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been logged out"])},
  "LoginSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome Back!"])},
  "Enter promo code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter promo code"])},
  "removeConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to remove it?"])},
  "removedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has been removed successfully"])},
  "savedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has been saved successfully"])},
  "initiatives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initiatives"])},
  "initiative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initiative"])},
  "payment methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Methods"])},
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
  "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
  "national_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National ID"])},
  "governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governorate"])},
  "university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["University"])},
  "college": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["College"])},
  "graduation_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graduation year"])},
  "know_about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How did you know about us?"])},
  "register_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register for"])},
  "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
  "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
  "id_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID copy front"])},
  "id_copy2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID copy back"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
  "Upload documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload documents"])},
  "uploaddocText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure that the card has been uploaded correctly, and then click on continue so that we can review your data and contact you as soon as possible."])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "useful_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Useful Link"])},
  "vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vision"])},
  "mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mission"])},
  "Take a Video Tour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take a Video Tour To Know Us"])},
  "WHY CHOOSE US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHY CHOOSE US"])},
  "About_home_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logicel was founded to provide life-transforming learning experiences to learners in the MENA region. Helping individuals & organizations drive performance with innovative learning solutions."])},
  "Mission_home_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating training as accessible, impactful and affordable as possible to boost the caliber of Arab youth to meet employment demands, and for business leaders to ensure the development of their learning curve."])},
  "Vision_home_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be the leading e-learning platform in the MENA region in terms of wide accessibility, technology, for individuals and corporates."])},
  "Facebook feed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook feed"])},
  "Approving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approving"])},
  "Approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])},
  "Finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])},
  "rejectedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, You are not accepted!"])},
  "acceptedAndPayText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The fees must be paid to obtain the approved certificate after the completion of the initiative examination via Meeza card from National Bank of Egypt."])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
  "full_namear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name in Arabic"])},
  "full_nameen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name in English"])},
  "pay now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay now"])},
  "Agreetoterms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to the terms and conditions"])},
  "exceededattemptsexam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have exceeded the number of attempts for this exam"])},
  "doYouWantToFinishExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to finish the exam?"])},
  "finish exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish Exam"])},
  "imageUploadText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload photos with a maximum of 2 megabytes of space and a jpg or png extension."])},
  "Ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
  "error_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect email or password"])},
  "api_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error in server, try again later"])},
  "required_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill out this field"])},
  "email_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a correct email address."])},
  "email_unique_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email has been already taken!"])},
  "phone_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the phone number contains numbers only ."])},
  "passwordlogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your password."])},
  "passwordcurrent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your current password."])},
  "passwordnew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be at least 8 characters or numbers."])},
  "passwordconfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must match the password confirmation."])},
  "name_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your full name"])},
  "job_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a job title"])},
  "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter subject"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write your message"])},
  "recaptcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recaptcha error, please try again later!"])},
  "national_id_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your national ID 14 Digits"])},
  "graduation_year_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your graduation your ex: 2020"])},
  "national_id_unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number is already registered"])},
  "national_id_digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must enter 14 numbers"])},
  "national_id.max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ًWrong national ID number"])},
  "national_id_age_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age does not match the national ID number"])},
  "national_id_age_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age does not match the national ID number"])},
  "image_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload image jpg,png,jpeg"])},
  "image_mimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload jpg,png,jpeg image"])},
  "image_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure the image is not bigger than 2 MB"])},
  "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
  "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
  "unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized"])},
  "Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
  "short_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short description"])},
  "keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keywords"])},
  "robot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["robot"])},
  "translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation"])},
  "Are you sure?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure?"])},
  "Competitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competitors"])},
  "Competitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competitor"])},
  "Competitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competitions"])},
  "Competition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competition"])},
  "Export to Excel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export to Excel"])},
  "click here to download the excel file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["click here to download the excel file"])},
  "Keep me logged in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep me logged in"])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "Show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
  "Policies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policies"])},
  "Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms"])},
  "barcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barcode"])},
  "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock"])},
  "discount_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount start"])},
  "discount_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount end"])},
  "Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])}
}