import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/enrollmentfiles',
        name: 'Enrollment Files',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/EnrollmentFiles.vue'),
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/not-found-on-platfrom',
        name: 'Not Found On Platform',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/NotFoundOnPlatform.vue'),
      },
      {
        path: '/students',
        name: 'Students',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'Students',
            component: () => import('@/views/students/Students'),
          },
          {
            path: 'add',
            name: 'Add Student',
            component: () => import('@/views/students/StudentsAdd'),
          },
          {
            path: 'edit/:id',
            name: 'Edit Student',
            component: () => import('@/views/students/StudentsEdit'),
          },
          {
            path: 'show/:id',
            name: 'Show Student',
            component: () => import('@/views/students/StudentsShow'),
          },
        ],
      },

      {
        path: '/reader-students',
        name: 'My Applications',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'My Applications',
            component: () => import('@/views/students/StudentsReader'),
          },
          {
            path: 'show/:id',
            name: 'Show Student To Reader',
            component: () => import('@/views/students/StudentsShow'),
          },
        ],
      }, 
      {
        path: '/groups',
        name: 'Groups',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'Groups',
            component: () => import('@/views/groups/Groups'),
          },
          {
            path: 'add',
            name: 'Add Groups',
            component: () => import('@/views/groups/GroupsAdd'),
          },
          {
            path: 'edit/:id',
            name: 'Edit Groups',
            component: () => import('@/views/groups/GroupsEdit'),
          },
          {
            path: 'group-reviewer-report',
            name: 'Reader Students Report',
            component: () => import('@/views/groups/ReviewerGroupReport'),
          }, 
          {
            path: 'group-scoring-reviews-report',
            name: 'Reader Scoring Students',
            component: () => import('@/views/groups/ScoringReviews'),
          }, 
        ],
      },
 
      {
        path: '/users',
        name: 'Users',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '',
            name: 'Users',
            component: () => import('@/views/users/Users'),
          },
          {
            path: 'add',
            name: 'Add User',
            component: () => import('@/views/users/UsersAdd'),
          },
          {
            path: 'edit/:id',
            name: 'Edit User',
            component: () => import('@/views/users/UsersEdit'),
          },
        ],
      }, 

      {
        path: '/jotform',
        name: 'Jotform',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: 'jotform_updates',
            name: 'JotForm Updates',
            component: () => import('@/views/settings/UpdateFormApplications'),
          },
        ],
      }, 

      {
        path: '/emails',
        name: 'Emails',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/emails',
            name: 'Emails',
            component: () => import('@/views/emails/Emails'),
          },
          {
            path: 'add',
            name: 'Add Emails',
            component: () => import('@/views/emails/EmailsAdd'),
          },
          {
            path: 'edit/:id',
            name: 'Edit Emails',
            component: () => import('@/views/emails/EmailsEdit'),
          },
        ],
      }, 

      
],
}, 
  {
    path: '/auth',
    redirect: '/pages/404',
    name: 'Auth',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [

      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/auth/Login'),
      },
      {
        path: 'forget-password',
        name: 'ForgetPassword',
        component: () => import('@/views/auth/ForgetPassword'),
      },
      {
        path: 'reset-password/:email',
        name: 'ResetPassword',
        component: () => import('@/views/auth/ResetPassword'),
      },
    ],
  },
  
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

const except = ['ForgetPassword', 'ResetPassword', ]
router.beforeEach((to, from, next) => {
  let token = store.state.token
 // console.log('router ==' + token)
  if (to.name !== 'Login' && !token && !except.includes(to.name))
    next({ name: 'Login' })
  else next()
})

export default router
