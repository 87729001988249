export default {
  "Menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القائمة"])},
  "Post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منشور"])},
  "Posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنشورات"])},
  "Dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوحة القيادة"])},
  "User Roles & Permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادوار المستخدمين و الاذون"])},
  "Role Permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اذون الدور"])},
  "Role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دور"])},
  "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])},
  "customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملاء"])},
  "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العميل"])},
  "Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتج"])},
  "Products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنتجات"])},
  "Categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاقسام"])},
  "All Customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل العملاء"])},
  "Leads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عملاء محتملين"])},
  "Clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عملاء حالين"])},
  "Lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عميل محتمل"])},
  "Client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عميل حالى"])},
  "My Customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عملائى"])},
  "My Leads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عملائى المحتملين"])},
  "My Clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عملائى الحالين"])},
  "Notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملاحظات"])},
  "All Notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل الملاحظات"])},
  "Scheduled Notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملاحظات مجدولة"])},
  "My Notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملاحظاتى"])},
  "My Scheduled Notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملاحظاتى المجدولة"])},
  "Activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الانشطة"])},
  "All Activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل الانشطة"])},
  "Open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المفتوح"])},
  "Alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تنبية"])},
  "Priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاولوية"])},
  "Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمات"])},
  "All Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل الخدمات"])},
  "Renewal Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمات مجددة"])},
  "Permanent Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمات نهائية"])},
  "Waiting Renewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتظر التجديد"])},
  "Waiting Validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منتظر الاعتماد"])},
  "My Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدماتى"])},
  "My Renewal Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدماتى المجددة"])},
  "My Permanent Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدماتى النهائية"])},
  "My Waiting Renewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدماتى المنتظرة التجديد"])},
  "My Waiting Validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدماتى المنتظرة الاعتماد"])},
  "Tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهام"])},
  "Task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مهمة"])},
  "All Tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل المهام"])},
  "Open Tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهام المفتوحة"])},
  "Scheduled Tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهام المجدولة"])},
  "Closed Tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهام المغلقة"])},
  "My Tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مهامى"])},
  "My Open Tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ماهمى المفتوحة"])},
  "My Scheduled Tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مهامى المجدولة"])},
  "My Closed Tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مهامى المغلقة"])},
  "Reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير"])},
  "Performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاداء"])},
  "User Performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اداء المستخدمين"])},
  "My Performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادائى"])},
  "Setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاعدادات"])},
  "Company Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن الشركة"])},
  "Taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الضرائب"])},
  "Users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستخدمين"])},
  "User Permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اذون المستخدمين"])},
  "Customer Types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع العملاء"])},
  "Reasons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسباب"])},
  "Results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النتائج"])},
  "Interests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاهتمام"])},
  "Types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الانواع"])},
  "Reached By": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الوصول"])},
  "Currencies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملات"])},
  "Countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البلاد"])},
  "States": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحافظات"])},
  "Cities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدن"])},
  "Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجالات"])},
  "Tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الضريبة"])},
  "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستخدم"])},
  "User Permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اذن المستخدم"])},
  "Permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اذن"])},
  "Permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأذون"])},
  "Customer Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع العميل"])},
  "Reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السبب"])},
  "Result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النتيجة"])},
  "Interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاهتمام"])},
  "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النوع"])},
  "Currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العملة"])},
  "Country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البلد"])},
  "State": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحافظة"])},
  "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدينة"])},
  "Field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجال"])},
  "Business Field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجال العمل"])},
  "Post Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكود البريدى"])},
  "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكترونى"])},
  "Value/Class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القيمة/التصنيف"])},
  "Website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموقع"])},
  "Logo image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة الشعار"])},
  "Create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضف"])},
  "Edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل"])},
  "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف"])},
  "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
  "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسال"])},
  "Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملاحظة"])},
  "Internal Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملاحظات داخلية"])},
  "Close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اغلاق"])},
  "View": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض"])},
  "Online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اونلاين"])},
  "Next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالى"])},
  "Pervious": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السابق"])},
  "Account Manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدير الحساب"])},
  "Company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشركة"])},
  "Created at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الاضافة"])},
  "Updated at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ التحديث"])},
  "Action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجراء"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصال"])},
  "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضف"])},
  "Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمة"])},
  "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم"])},
  "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا"])},
  "Validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعتمدت"])},
  "Start Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ البدأ"])},
  "End Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ النهاية"])},
  "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التاريخ"])},
  "Assigned To": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المسئول"])},
  "Comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليق"])},
  "Service History": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الخدمة"])},
  "Service Informaton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن الخدمة"])},
  "Add by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بواسطة"])},
  "Customer Informaton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن العميل"])},
  "Last 7 days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اخر 7 ايام"])},
  "Last 30 days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اخر 30 يوم"])},
  "Last 3 Months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اخر 3 اشهر"])},
  "Last 6 Months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اخر 6 اشهر"])},
  "Last 12 Months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اخر 12 شهر"])},
  "Life Time Performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل الاوقات"])},
  "Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
  "Logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشعار"])},
  "Logo_white": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشعار الابيض"])},
  "Logo_black": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشعار الاسود"])},
  "Company Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن الشركة"])},
  "Customer Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عن العميل"])},
  "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم"])},
  "Percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النسبة"])},
  "Currency Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم العملة"])},
  "Unit Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الوحدة"])},
  "Subunit Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الوحدة الجزئية"])},
  "Currency Rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقييم العملة"])},
  "Rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقيم"])},
  "Unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوحدة"])},
  "Subunit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوحدة الجزئية"])},
  "Country Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم الشركة"])},
  "Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكود"])},
  "Calling Code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كود الاتصال"])},
  "Progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقدم"])},
  "Login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دخول"])},
  "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خروج"])},
  "Register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل"])},
  "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التليفون"])},
  "Mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحمول"])},
  "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العنوان"])},
  "Customer Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملف العميل"])},
  "Create Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضف اتصال"])},
  "Edit Customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل بيانات العميل"])},
  "Image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة"])},
  "Position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوظيفة"])},
  "Edit Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل اتصال"])},
  "Add Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضف خدمة"])},
  "Price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السعر"])},
  "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاجمالى"])},
  "Service Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الخدمة"])},
  "Validate & Invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعتمد و اصدر فاتورة"])},
  "Renewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تجديد"])},
  "Print Invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اطبع الفاتورة"])},
  "Edit Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الخدمة"])},
  "Service will be invoiced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيتم اصدار فاتورة للخدمة ولن يمكنك تعديل الخدمة"])},
  "Validate Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعتمد الخدمة"])},
  "Validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعتمد"])},
  "Add Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضف ملاحظة"])},
  "Mark As Seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["علم كقرأت"])},
  "Seen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قرأت"])},
  "Add Activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضف نشاط"])},
  "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "Interested in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مهتم ب"])},
  "Edit Activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل النشاط"])},
  "All Open Activites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل الانشطة المفتوحة"])},
  "All Alert Activites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل الانشطة بتنبية"])},
  "All Priority Activites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل الانشطة باولويات"])},
  "Show Activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض النشاط"])},
  "Done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التنفيذ"])},
  "Mark As Done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["علم تم التنفيذ"])},
  "Companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشركات"])},
  "Events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاحداث"])},
  "Event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحدث"])},
  "Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["القسم"])},
  "Owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المالك"])},
  "Contact Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشخص المفوض"])},
  "Tax Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم الضريبى"])},
  "Commercial Registry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السجل التجارى"])},
  "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
  "contactus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصل بنا"])},
  "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقدمة"])},
  "Edit About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل من نحن"])},
  "Edit Intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل المقدمة"])},
  "sliders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الصور"])},
  "Conferance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المؤتمر"])},
  "Attendants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحضور"])},
  "Rejected Attendants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحضور المرفوضين"])},
  "Adult Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد البالغين"])},
  "Childern Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الاطفال"])},
  "Childern Age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعمار الاطفال"])},
  "Arrival Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الوصول"])},
  "Departure Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ المغادرة"])},
  "Flight Details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الرحلة الطياران"])},
  "Package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["باقة"])},
  "Double Room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غرفة مزدوجة"])},
  "Single Room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غرفة مفردة"])},
  "Tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تذاكر"])},
  "Edit Contact Us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل اتصل بنا"])},
  "Languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغات"])},
  "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغة"])},
  "Providers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزودون"])},
  "Provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مزود"])},
  "our courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البرامج التدريبية"])},
  "Courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البرامج التدريبية"])},
  "Course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البرنامج التدريبي"])},
  "Short Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([",وصف سريع"])},
  "Discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خصم"])},
  "Skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المهارات"])},
  "Duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدة"])},
  "Item_langs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لغة المنتج"])},
  "Item_lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لغة المنتج"])},
  "Video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فيديو"])},
  "Videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فيديوهات"])},
  "Sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحاضرات"])},
  "Session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محاضرة"])},
  "Page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفحة"])},
  "Pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفحات"])},
  "Slider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض الصور"])},
  "Sliders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عروض الصور"])},
  "Navbar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملاحة"])},
  "Follow Me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تابعونى"])},
  "Follow us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تابعونا"])},
  "Recent Posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احث المنشورات"])},
  "Get Interesting News": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشترك ليصلك كل جديد"])},
  "Older Posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقالات قديمة"])},
  "Newer Posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقالات جديدة"])},
  "Personal Blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدونة شخصية"])},
  "Read More": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اقراء المزيد"])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشترك"])},
  "Your email address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان البريد الالكترونى"])},
  "Continue Reading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اقراء المزيد"])},
  "YOU MIGHT ALSO LIKE...": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقالات قد تحبها"])},
  "Subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموضوع"])},
  "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسالة"])},
  "Thank you I will contact you as soon as possible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شكرا لك سوف اقوم بالتواصل معك فى اقرب وقت ممكن"])},
  "Shop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتجر"])},
  "Catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكاتلوج"])},
  "More Services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزيد من الخدمات"])},
  "Customer Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمة العملاء"])},
  "Messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسائل"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
  "Partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شركائنا"])},
  "bundles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الباقات"])},
  "bundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["باقة"])},
  "High Quality Courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعلم الآن"])},
  "Save More With Our Bundles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وفر مع باقاتنا"])},
  "Start Learning Now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابداء التعلم الان"])},
  "Beginner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبتدئ"])},
  "Intermediate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متوسط"])},
  "Advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متقدم"])},
  "About the Course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عن البرنامج التدريبي"])},
  "Course by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البرنامج التدريبي بواسطة"])},
  "Read full biography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اقراء السيرة الذاتية"])},
  "Views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشاهدات"])},
  "Sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبيعات"])},
  "Overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نظرة عامة"])},
  "Buy now and save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشترى الان و وفر"])},
  "Buy now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشترى الان"])},
  "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
  "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاكيد كلمة المرور"])},
  "Settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاعدادات"])},
  "My Courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["برامجى التدريبية"])},
  "My Wishlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الامانى"])},
  "Wishlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الامانى"])},
  "Personal Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات شخصية"])},
  "Account Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الحساب"])},
  "Current Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الحالية"])},
  "New Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الجديدة"])},
  "Change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير"])},
  "Check out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة الشراء"])},
  "Triple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ثلاثية"])},
  "Cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عربة التسوق"])},
  "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب"])},
  "Orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلبات"])},
  "My Orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلباتى"])},
  "Reference Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم المرجعى"])},
  "Payment Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة الدفع"])},
  "Payment Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الدفع"])},
  "Order Number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم الدفع"])},
  "Paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الدفع"])},
  "Waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتظار"])},
  "Available Languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغات المتاحة"])},
  "Signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء حساب"])},
  "Remember Me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تذكرنى"])},
  "View All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض المزيد"])},
  "Share on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شارك"])},
  "Now Playing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يعرض الان"])},
  "Terms of Use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط الاستخدام"])},
  "Privacy Policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسة الخصوصية"])},
  "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السؤال"])},
  "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسئلة"])},
  "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["امتحان"])},
  "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الامتحانات"])},
  "Answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاجابة"])},
  "Answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاجابات"])},
  "Score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرجة"])},
  "Scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدرجات"])},
  "Exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["امتحان"])},
  "Exams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["امتحانات"])},
  "Start The Exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابدأ الامتحان"])},
  "Minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دقائق"])},
  "You need to buy the course for playing this": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب شراء البرنامج التدريبي لتشغيل هذا"])},
  "Reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المراجعات"])},
  "Leave a Review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قول رايك فى البرنامج التدريبي"])},
  "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مراجعة"])},
  "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الغاء"])},
  "Quiz Questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أسئلة الامتحان"])},
  "Group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموعة"])},
  "Groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموعات"])},
  "Create Group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء مجموعة"])},
  "Members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاعضاء"])},
  "Member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عضو"])},
  "Add Course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضف البرنامج التدريبي"])},
  "Add Member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضف عضو"])},
  "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقرير"])},
  "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نشط"])},
  "Deactivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متوقف"])},
  "Waiting Review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قيد المراجعة"])},
  "Search results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نتائج البحث"])},
  "Logicel For Business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لوجيسل للشركات"])},
  "Thank you we will contact you as soon as possible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شكرا لك سوف نقوم بالتواصل معك فى اقرب وقت ممكن"])},
  "Expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتهى"])},
  "Cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الغاء"])},
  "Failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشل"])},
  "Created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الانشاء"])},
  "Payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدفع"])},
  "Payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدفوعات"])},
  "Ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جاهز"])},
  "Cycle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دورة"])},
  "Cycles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دورات"])},
  "Your Score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجتك"])},
  "Passing Degree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["درجة النجاح"])},
  "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحالة"])},
  "Pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ناجح"])},
  "Fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["راسب"])},
  "SUMMARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الملخص"])},
  "TOTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاجمالى"])},
  "EGP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جنية"])},
  "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقيم"])},
  "Forget Your Password?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل نسيت كلمة المرور؟"])},
  "Attempt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محاولة"])},
  "Cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الغطاء"])},
  "exams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["امتحانات"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التالى"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السابق"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل"])},
  "generate certifcate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اصدار شهادة"])},
  "cantTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يمكنك الامتحان الان  امامك فترة اتتظار للامتحان مرة اخرى "])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ايام"])},
  "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرابط"])},
  "Media Library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكتبة الوسائط"])},
  "promos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عروض ترويجية"])},
  "promo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض ترويج"])},
  "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرصيد"])},
  "Date Start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ البداء"])},
  "Date End": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الانتهاء"])},
  "No data or Promo is expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لايوجد بيانات او ان العرض منتهى"])},
  "You have used a promo on this order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الطلب لا يستفاد من العرض او تم استخدام عرض اخر على الطلب"])},
  "applied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مطبق"])},
  "No items found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد بيانات"])},
  "Data has been updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تحديث البيانات"])},
  "Please enter correct current password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل كلمة المرور الحالية بشكل صحيح"])},
  "statusDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف الحالة"])},
  "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات"])},
  "Create New Account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل حساب جديد"])},
  "passhint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" الا تقل عن 8 حروف او ارقام"])},
  "Sorting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الترتيب"])},
  "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوظيفة"])},
  "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طالب"])},
  "graduated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خريج"])},
  "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موظف"])},
  "employer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صاحب عمل"])},
  "verify_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد التسجيل"])},
  "Hi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبا"])},
  "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شكرا"])},
  "Your mail is verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التحقق من بريدك "])},
  "Your mail is verified, thank you!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التحقق من بريدك ، شكرا لك!"])},
  "No data found!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لاتوجد بيانات!"])},
  "Your mail is verified, you need to login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم التحقق من بريدك ، تحتاج إلى تسجيل الدخول"])},
  "check your mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الارسال تفقد بريدك!"])},
  "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعادة الارسال"])},
  "Exercise Folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملف التدريبات"])},
  "certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشهادات"])},
  "certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شهادة"])},
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شارك"])},
  "uploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" اسحب صورة هنا او اضغط لرفع واحدة"])},
  "commentUnderReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعليقك قيد المراجعة"])},
  "facebookfollow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تابعونا على الفيسبوك"])},
  "twitterfollow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تابعونا على تويتر"])},
  "linkedinfollow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تابعونا على لينكدان"])},
  "instagramfollow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تابعونا على انستجرام"])},
  "youtubefollow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تابعونا على اليوتيوب"])},
  "Add to cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضف للعربة"])},
  "Remove from cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف من عربة التسوق"])},
  "Add to wishlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضف لقائمة الامنيات"])},
  "Remove from wishlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حذف من قائمة الامنيات"])},
  "Play": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تشغيل"])},
  "Facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فيسبوك"])},
  "Twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تويتر"])},
  "Linkedin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لينكدان"])},
  "LogoutSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الخروج بنجاح"])},
  "LoginSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبا بك!"])},
  "Enter promo code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل كود العرض "])},
  "removeConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تريد حذفها؟"])},
  "removedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الحذف بنجاح"])},
  "savedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم لحفظ بنجاح"])},
  "initiatives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبادرات"])},
  "initiative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبادرة"])},
  "payment methods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طرق الدفع"])},
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["النوع"])},
  "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العمر"])},
  "national_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم القومى"])},
  "governorate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المحافطة"])},
  "university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجامعة"])},
  "college": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكلية"])},
  "graduation_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سنة التخرج"])},
  "know_about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف سمعت عنا؟"])},
  "register_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل فى"])},
  "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ذكر"])},
  "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انثى"])},
  "id_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة البطاقة وجة"])},
  "id_copy2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صورة البطافة ظهر"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع"])},
  "Upload documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رفع الاوراق المطلوبة"])},
  "uploaddocText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى التأكد من ان البطاقة تم رفعها بشكل صحيح ، ثم قم بالضغط على الإستمرار حتى نتمكن من مراجعة بياناتك والتواصل معك في أقرب وقت ممكن"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاستمرار"])},
  "useful_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["روابط مهمة"])},
  "vision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رؤيتنا"])},
  "mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مهمتنا"])},
  "Take a Video Tour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بجولة فيديو للتعرف علينا"])},
  "WHY CHOOSE US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لماذا تختارنا"])},
  "About_home_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشأت منصة لوجيسل بهدف تقديم تجربه تعلم جديدة ومختلفة في منطقة الشرق الأوسط وشمال إفريقيا. لمساعدة الأفراد والمؤسسات على تعزيز الأداء من خلال حلول التعلم المبتكرة."])},
  "Mission_home_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خلق فرص تدريبية ذات قيمة مرتفعة يمكن الوصول إليها بسهولة وبأسعار معقولة قدر الإمكان لتعزيز  مهارات الشباب المصري والعربي لتلبية متطلبات التوظيف ، وكذلك لقادة الأعمال لضمان تطوير منحنى التعل الخاص بهم وبمؤسساتهم."])},
  "Vision_home_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أن تصبح لوجيسل منصة التعلم الإلكتروني الرائدة في منطقة الشرق الأوسط وشمال إفريقيا من حيث القيمة مع سهولة الوصول تكنولوجياً للأفراد والشركات."])},
  "Facebook feed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موجز الفيسبوك"])},
  "Approving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموافقة"])},
  "Approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم الموافقة"])},
  "Finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انهاء"])},
  "rejectedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عفوا لم يتم قبولكم"])},
  "acceptedAndPayText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب دفع الرسوم للحصول على الشهادة المعتمدة بعد الانتهاء من اختبار المبادرة عن طريق بطاقة ميزة من البنك الأهلي المصري."])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوقت"])},
  "full_namear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإسم رباعي باللغة العربية"])},
  "full_nameen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإسم رباعي باللغة الإنجليزي"])},
  "acceptedText_init": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى العلم انه تم قبولك في مبادرة :name بنجاح ، برجاء الضغط على الإستمرار لبدء تصفح البرامج التدريبية وسوف نعلنكم قريباً عن اتاحة رابط الإختبارات النهائية مع أطيب التمنيات بالتوفيق"])},
  "pay now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["افع الان"])},
  "Agreetoterms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اوافق على الشروط والاحكام"])},
  "exceededattemptsexam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تجاوزت عدد المحاولات لهذا الامتحان"])},
  "doYouWantToFinishExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تريد انهاء الامتحان؟"])},
  "finish exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انهاء الامتحان"])},
  "imageUploadText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى رفع الصور بحد اقصى 2 ميجا للمساحة و امتداد jpg او png"])},
  "Ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موافق"])},
  "error_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ فى عنوان البريد او كلمة المرور"])},
  "api_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ فى الخادم حاول مرة اخرة فى وقت لاحق"])},
  "required_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["املأ هذا الحقل."])},
  "email_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل بريد اللكترونى صحيح."])},
  "email_unique_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكترونى مسجل من قبل"])},
  "phone_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل رقم الهاتف مكون من ارقام فقط."])},
  "passwordlogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل كلمة المرور الخاصة بك"])},
  "passwordcurrent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل كلمة المرور الحالية"])},
  "passwordnew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور يجب الا تقل عن 8 احرف او ارقام"])},
  "passwordconfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور يجب ان تكون مطابقة لتاكيد كلمة المرور"])},
  "name_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسمك بالكامل"])},
  "job_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر وظيفة"])},
  "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل عنوان الرسالة"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب الرسالة"])},
  "recaptcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ فى recaptcha حاول فى مرة اخرى فى وقت لاحق"])},
  "national_id_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل الرقم القومى 14 رقم"])},
  "graduation_year_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل سنة التخرج مثال: 2020"])},
  "national_id_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل الرقم القومى"])},
  "national_id_unique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم مسجل من قبل"])},
  "national_id_digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجب ادخال 14 رقم"])},
  "national_id_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ فى الرقم القومى"])},
  "national_id_age_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السن غير مطابق مع الرقم القومى"])},
  "national_id_age_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" السن غير مطابق مع الرقم القومى"])},
  "image_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاكد من رفع صورة jpg,png,jpeg"])},
  "image_mimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاكد من رفع صورة بامتداد jpg,png,jpeg "])},
  "image_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاكد ان حجم الصورة لايتجاوز 2 مبجا"])},
  "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الاول"])},
  "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الاخير"])},
  "unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير مصرح"])},
  "Information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف"])},
  "short_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصف المختصر"])},
  "keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكلمات المفتحية"])},
  "robot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الروبوت"])},
  "translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ترجمة"])},
  "Are you sure?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل انت متاكد؟"])},
  "Competitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المنافسون"])},
  "Competitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منافس"])},
  "Competitions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسابقات"])},
  "Competition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسابقة"])},
  "Export to Excel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصدير لاكسل"])},
  "click here to download the excel file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضغط هنا لتحميل ملف اكسل"])},
  "Keep me logged in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احتفظ بتسجيل الدخول"])},
  "Back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رجوع"])},
  "Show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض"])},
  "Policies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["السياسات"])},
  "Terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشروط"])},
  "barcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الباركود"])},
  "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المخزون"])},
  "discount_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بدأ الخصم"])},
  "discount_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتهاء الخصم"])},
  "Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])}
}