
export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
    /*badge: {
      color: 'primary',
      text: 'NEW',
    },*/
  },
  {
    component: 'CNavTitle',
    name: 'candidates',
  },
  {
    component: 'CNavGroup',
    name:  'Students',
    to: '/students',
    icon: 'cilAddressBook',
    showIf: "store.user.permissions.includes('Colors')",
    items: [
      {
        component: 'CNavItem',
        name: 'Students',
        to: '/students',
      },
     
     /* {
        component: 'CNavItem',
        name: 'Add Students',
        to: '/students/add',
      },*/
    ],
  },
  {
    component: 'CNavItem',
    name: 'Not Found',
    to: '/not-found-on-platfrom',
    icon: 'cilPuzzle',
  },
  {
    component: 'CNavTitle',
    name: 'Review',
  },
  {
    component: 'CNavGroup',
    name:  'Groups',
    to: '/groups',
    icon: 'cilCalculator',
    items: [
      {
        component: 'CNavItem',
        name: 'Groups',
        to: '/groups',
      },
      {
        component: 'CNavItem',
        name: 'Add Groups',
        to: '/groups/add',
      },
      {
        component: 'CNavItem',
        name: 'Group Reviewers Report',
        to: '/groups/group-reviewer-report',
      },
      {
        component: 'CNavItem',
        name: 'Reader Scoring Students',
        to: '/groups/group-scoring-reviews-report',
      },
    ],
  },
 /* {
    component: 'CNavGroup',
    name:  'Application Readers',
    to: '/reader-students-report',
    icon: 'cil-file',
    items: [
      {
        component: 'CNavItem',
        name: 'Report',
        to: '/reader-students-report',
      },
    ],
  },*/
  {
    component: 'CNavTitle',
    name: 'settings',
  },
  {
    component: 'CNavGroup',
    name:  'Users',
    to: '/users',
    icon: 'cil-people',
    items: [
      {
        component: 'CNavItem',
        name: 'Users',
        to: '/users',
      },
      {
        component: 'CNavItem',
        name: 'Add Users',
        to: '/users/add',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name:  'Emails',
    to: '/emails',
    icon: 'cilEnvelopeClosed',
    items: [
      {
        component: 'CNavItem',
        name: 'Emails',
        to: '/emails',
      },
      {
        component: 'CNavItem',
        name: 'Add Emails',
        to: '/emails/add',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name:  'JotForm',
    to: '/jotform',
    icon: 'cilCloudDownload',
    items: [
      {
        component: 'CNavItem',
        name: 'JotForm Updates',
        to: '/jotform/jotform_updates',
      },
    ],
  },

  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
]
